import { CustomerResponseDto } from '@app/api/external-api';
import { saveToken, setIsRegistered } from '@app/features/auth/auth.utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

interface AuthState {
  user: CustomerResponseDto | null;
  access: {
    accessToken: string | null;
    phone: string | null;
  };
}

const initialState: AuthState = {
  user: null,
  access: {
    accessToken: null,
    phone: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<CustomerResponseDto>) {
      setIsRegistered(true);
      state.user = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      saveToken(action.payload);
      const { username } = jwtDecode<{ username: string; isRegistered: boolean }>(action.payload);
      state.access = {
        phone: username,
        accessToken: action.payload,
      };
    },
    loggedOut(state) {
      state.access = {
        accessToken: null,
        phone: null,
      };
      state.user = null;
    },
  },
});

export default authSlice.reducer;

export const { setAccessToken, setUserData, loggedOut } = authSlice.actions;
