
import { FC } from 'react';
import cx from 'classnames';

import './badge.scss';

type BadgeProps = {
  variant: 'desktop' | 'burger' | 'drawer',
  content: string | number
};

export const Badge: FC<BadgeProps> = ({
  variant,
  content
}) => {

  return (
    <div className={cx('badge', variant)}>{content}</div>
  );
};
