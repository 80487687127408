import { ReactComponent as AccountIcon } from '@app/assets/icons/account.svg';
import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { ReactComponent as LogoIcon } from '@app/assets/icons/logo.svg';
import { ReactComponent as MapIcon } from '@app/assets/icons/map.svg';
import { ReactComponent as SearchIcon } from '@app/assets/icons/search.svg';

import { ArrowLink, BurgerMenu, Dropdown, NavBar, Badge } from '@app/components';
import { LocalStorageItem, SiteMap, UrlQueryParam } from '@app/constants';
import { NAVBAR_DATA } from '@app/data/navbar.data';
import { ScrollDirection, useEnhancedTranslation, useMedia, useScrollDirection, useWindowSize } from '@app/hooks';
import { Language, changeLang } from '@app/i18n';
import { addSuggestionItem, ymGoal } from '@app/utils';
import cx from 'classnames';
import { gsap } from 'gsap';
import i18next from 'i18next';
import { differenceInMinutes } from 'date-fns';
import identity from 'lodash/identity';
import upperCase from 'lodash/upperCase';
import { useLayoutEffect, useMemo, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import './header.scss';

export const Header = (): JSX.Element => {
  const { isDesktop, isPortable } = useMedia();
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'header' });
  const timelineRef = useRef<gsap.core.Timeline>();
  const { scrollDirection, scrollYOffset } = useScrollDirection(15);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState(pathname === SiteMap.SEARCH);
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const windowSize = useWindowSize();
  const [isVisibleBadge, setIsVisibleBadge] = useState(false);

  useEffect(() => {
    const badgeFixedTime = localStorage.getItem(LocalStorageItem.BADGE_FIXED);
    const storageVisible = localStorage.getItem(LocalStorageItem.BADGE_VISIBLE) === 'true';
    if (
      !badgeFixedTime ||
      (badgeFixedTime && differenceInMinutes(Date.now(), +badgeFixedTime) >= 30)
    ) {
      setIsVisibleBadge(true);
      localStorage.setItem(LocalStorageItem.BADGE_FIXED, Date.now().toString());
      localStorage.setItem(LocalStorageItem.BADGE_VISIBLE, 'true');
    } else setIsVisibleBadge(storageVisible);
  }, []);

  useEffect(() => {
    localStorage.setItem(LocalStorageItem.BADGE_VISIBLE, isVisibleBadge ? 'true' : 'false');
  }, [isVisibleBadge]);

  const navNode = useMemo(
    () => (
      <NavBar
        data={isPortable ? NAVBAR_DATA : NAVBAR_DATA.filter((item) => item.link !== SiteMap.CERTIFICATES)}
        className="header__nav"
        listClassName="header__links"
        itemClassName="header__link"
      />
    ),
    [isPortable],
  );

  const changeLangNode = (
    <Dropdown<Language>
      className="header__lang"
      options={Object.values(Language)}
      defaultOption={i18next.language as Language}
      onValueChange={changeLang}
      selectId={identity}
      selectValue={upperCase}
    />
  );

  const joinBtnNode = (
    <Link
      onClick={() => ymGoal('connecttop')}
      to={isDesktop ? SiteMap.LOYALITY_OVERVIEW : SiteMap.ACCOUNT}
      className="header__join-btn"
    >
      {t('join')}
    </Link>
  );

  const toggleSearch = (): void => {
    setSearchActive(!searchActive);
    searchActive
      ? (() => {
        timelineRef.current?.reverse();
        setQuery('');
      })()
      : timelineRef.current?.play();
  };

  const goToSearch = (): void => {
    addSuggestionItem(query);
    navigate(`${SiteMap.SEARCH}?${UrlQueryParam.SEARCH_QUERY}=${query}`);
  };

  useLayoutEffect(() => {
    if (isPortable) return undefined;
    const searchSelector = '.header__nav-n-search .header__search';
    const navSelector = '.header__nav-n-search .header__nav';
    timelineRef.current = gsap
      .timeline({
        defaults: { duration: 0.5 },
        reversed: !searchActive,
      })
      .set(searchSelector, { display: 'block' })
      .set(navSelector, { overflow: 'hidden' })
      .to(navSelector, {
        width: 0,
        ease: 'power1.inOut',
        flexShrink: 1,
        flexGrow: 0,
      })
      .to(searchSelector, {
        width: 'auto',
        ease: 'power1.inOut',
        flexGrow: 1,
        flexShrink: 0,
        marginLeft: '5vw',
        marginRight: '5vw',
        paddingLeft: '0.6em',
        paddingRight: '0.6em',
      })
      .to('.header__search-btn', {
        autoAlpha: 1,
        duration: 0.3,
      });
    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width, isPortable]);

  return (
    <header
      className={cx('header', {
        'header--hidden': scrollDirection === ScrollDirection.DOWN,
        'header--filled': scrollYOffset > window.innerHeight / 8,
      })}
    >
      <Link to={SiteMap.HOME}>
        <LogoIcon className="header__logo" />
      </Link>
      <div style={{ display: isDesktop ? undefined : 'none' }} className="header__nav-n-search">
        {navNode}
        <input
          name="main-search"
          className="header__search text-input"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={({ key }) => key === 'Enter' && goToSearch()}
          placeholder={tRoot('search.placeholder') || undefined}
        />
        <ArrowLink to={`${SiteMap.SEARCH}?${UrlQueryParam.SEARCH_QUERY}=${query}`} className="header__search-btn" />
      </div>
      <div className="header__right">
        {isDesktop && (
          <div className="header__controls">
            <div className="header__control">
              {!searchActive && (
                <SearchIcon className="header__control" title={t('search') || undefined} onClick={toggleSearch} />
              )}
              {searchActive && <CrossIcon className="header__control-icon--cross" onClick={toggleSearch} />}
            </div>

            <Link to={SiteMap.MAP} onClick={() => ymGoal('depstolay')} className="header__control">
              <MapIcon title={t('mallMap') || undefined} />
            </Link>
            <Link to={SiteMap.ACCOUNT}
              onClick={() => {
                ymGoal('perare');
                setIsVisibleBadge(false);
              }}
              className="header__control"
            >
              <AccountIcon title={t('account') || undefined} />
              {isVisibleBadge && <Badge variant='desktop' content={1} />}
            </Link>
            {changeLangNode}
          </div>
        )}
        {joinBtnNode}
        {isPortable && (
          <BurgerMenu
            className="header__burger"
            headerClassName="header__burger-header"
            contentClassName="header__burger-content"
            visibleBadge={isVisibleBadge}
            headerContent={
              <>
                {joinBtnNode}
                {isVisibleBadge &&
                  <Link to={isDesktop ? SiteMap.LOYALITY_OVERVIEW : SiteMap.ACCOUNT}
                    onClick={() => {
                      ymGoal('connecttop');
                      setIsVisibleBadge(false);
                    }}
                  >
                    <Badge variant='drawer' content={1} />
                  </Link>
                }
              </>
            }
          >
            {navNode}
            <div className="header__controls">
              {changeLangNode}
              <Link to={SiteMap.ACCOUNT} onClick={() => ymGoal('perare')} className="header__control">
                {t('account')}
              </Link>
              <Link to={SiteMap.MAP} onClick={() => ymGoal('depstolay')} className="header__control">
                {t('mallMap')}
              </Link>
              <Link to={SiteMap.SEARCH} className="header__control">
                {t('search')}
              </Link>
            </div>
          </BurgerMenu>
        )}
      </div>
    </header>
  );
};
