import { SiteMap } from '@app/constants';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@app/hooks';
import { useAppSelector } from '@app/hooks/redux';
import { useLazyAuthControllerRefreshQuery } from '@app/api/auth-api';
import { setAccessToken, setUserData } from '@app/store/reducers/authSlice';
import { useLazyExternalControllerFindOneQuery } from '@app/api/external-api';

export const CheckAvailableAuth: FC<{ children: ReactElement }> = ({ children }) => {
  const [visibleLogin, setVisibleLogin] = useState<boolean>(false);
  const { phone, accessToken } = useAppSelector((state) => state.authReducer.access);
  const user = useAppSelector((state) => state.authReducer.user);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [checkUserExists, { data: checkUserExistsRes, error: checkUserExistsError }] =
    useLazyExternalControllerFindOneQuery();

  const [refresh, { data: refreshRes, error: refreshErr }] = useLazyAuthControllerRefreshQuery();

  useEffect(() => {
    if (phone && accessToken && user) {
      navigate(SiteMap.LOYALITY);
    }

    if (phone && accessToken && !user) {
      checkUserExists({ phone });
    }

    if (!phone && !accessToken && !user) {
      refresh();
    }
  }, [phone, user, accessToken]);

  useEffect(() => {
    console.log('refreshRes', refreshRes);
    if (refreshRes?.accessToken) {
      dispatch(setAccessToken(refreshRes.accessToken));
    }

    if (refreshRes === null) {
      setVisibleLogin(true);
    }
  }, [refreshRes]);

  useEffect(() => {
    if (refreshErr) {
      setVisibleLogin(true);
    }
  }, [refreshErr]);

  useEffect(() => {
    if (checkUserExistsRes) {
      dispatch(setUserData(checkUserExistsRes));
    }
  }, [checkUserExistsRes]);

  useEffect(() => {
    if (checkUserExistsError) {
      navigate(SiteMap.SIGN_UP);
    }
  }, [checkUserExistsError]);

  return visibleLogin && children ? children : <div style={{ height: '100vh' }} />;
};
