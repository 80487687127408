import { FeatureCollection, LineString, MultiPolygon, Point } from 'GeoJson';

export type Sortable = {
  sortOrder?: number;
};

export type LocalizedMap = Record<string, string>;

export type Image = Sortable & {
  url?: string;
};

export type Video = Image;

export type CardLink = Sortable & {
  id: string;
  name: LocalizedMap;
  type?: string;
  link: string;
  image?: Image;
};

export type ColorfulCard = {
  backgroundColor?: string;
  fontColor?: string;
};

export type CardRow<T extends CardLink> = T[];

export type CardProps<T extends object = object> = T & {
  className?: string;
  pictureClassName?: string;
  linkClassName?: string;
  captionClassName?: string;
};

export type CardLinkProps<T extends CardLink = CardLink> = CardProps<T> & T;

export type ColorfulCardLink = CardLink & ColorfulCard;

export type TDummyCard<T extends ColorfulCard = ColorfulCard> = T;

export type TBrandCard = CardLink & {
  floor?: number;
  cornerId?: string;
  cover?: Image;
  logo?: Image;
  description?: LocalizedMap;
  tags: TTag[];
};

export const isBrandCard = (card: CardLink): card is TBrandCard => {
  return card.type === 'brand';
};

export type FloorCard = {
  floor: number;
  brands: { name: LocalizedMap; shortDescription: LocalizedMap }[];
};

export enum StoryPublicationType {
  NEWS = 'news',
  PROJECT = 'project',
  EVENT = 'event',
}

export type TStoryCard = ColorfulCardLink & {
  banner?: Image;
  publishedDate?: Date;
  publicationType?: StoryPublicationType;
};

export const isStoryCard = (card: CardLink): card is TStoryCard => {
  return card.type === 'story';
};

export enum EventRegistrationType {
  FREE = 'free',
  REGISTRATION = 'registration',
  VIP = 'vip',
}

export type TEventCard = ColorfulCardLink & {
  period: {
    start: Date;
    end: Date;
  };
  registrationType?: EventRegistrationType;
};

export const isEventCard = (card: CardLink): card is TEventCard => {
  return card.type === 'event';
};

export type TRestaurantCard = CardLink & {
  floor?: number;
  cornerId?: string;
  logo?: Image;
  tags: TTag[];
};

export type TServiceCard = CardLink & {
  description?: LocalizedMap;
  floor?: number;
  cornerId?: string;
  discountLevels?: string[];
  loyalityDescription?: LocalizedMap;
};

export enum BlockType {
  TITLE = 'title',
  TEXT = 'text',
  BANNER = 'banner',
  PHOTO = 'photo',
  GALLERY = 'gallery',
}

export type Block =
  | {
      type: BlockType.TITLE | BlockType.TEXT;
      text: LocalizedMap;
    }
  | {
      type: BlockType.BANNER | BlockType.PHOTO | BlockType.GALLERY;
      images: Image[];
    }
  | {
      type: unknown;
      text: never;
      images: never;
    };

export type TTag = Sortable & {
  id: string;
  name: LocalizedMap;
  count?: number;
};

export type TSocialNetworks = {
  telegram?: string;
  vkontakte?: string;
  youtube?: string;
  ok?: string;
};

export type PageEntity = {
  id: string;
  name: LocalizedMap;
  images: Image[];
  descriptions: LocalizedMap[];
  tags: TTag[];
  floor?: number;
  cornerId?: string;
  link: string;
  logo?: Image;
};

export type Brand = PageEntity & {
  descriptions: [LocalizedMap, LocalizedMap, LocalizedMap | undefined];
  socialNetworks?: TSocialNetworks;
  banner?: Image;
  siteUrl?: string;
  gallery: Image[];
  events: TEventCard[];
  stories: TStoryCard[];
};

export type TEvent = PageEntity & {
  descriptions: [LocalizedMap, LocalizedMap];
  socialNetworks?: TSocialNetworks;
  banner?: Image;
  gallery: Image[];
  period: {
    start: Date;
    end: Date;
  };
  registrationType?: EventRegistrationType;
  events: TEventCard[];
  brands: TBrandCard[];
  fontColor?: string;
  startTime: string,
  endTime: string,
};

export type Restaurant = PageEntity & {
  descriptions: [LocalizedMap, LocalizedMap];
  socialNetworks?: TSocialNetworks;
  gallery: Image[];
  siteUrl?: string;
  menuUrl?: string;
};

export type Story = PageEntity & {
  descriptions: [LocalizedMap, LocalizedMap];
  socialNetworks?: TSocialNetworks;
  banner?: Image;
  publishedDate: Date;
  blocks: Block[];
  brands: TBrandCard[];
  events: TEventCard[];
};

export type Service = PageEntity & {
  descriptions: [LocalizedMap, LocalizedMap];
  banner?: Image;
};

export type TBanner = Sortable &
  ColorfulCard & {
    title?: LocalizedMap;
    button?: {
      text: LocalizedMap;
      link: string;
    };
    banner?: Image;
    video?: Video;
  };

export enum MapCornerType {
  FREE = 'free',
  BRAND = 'brand',
  FOOD = 'food',
  SERVICE = 'service',
  EVENT = 'popup',
  DRESSING_ROOM = 'dressingroom',
  ELEVATOR = 'lift',
  EXIT = 'exit',
  ATM = 'atm',
  WC = 'wc',
  INFO = 'info',
  BABY_ROOM = 'babycareroom',
  WAREHOUSE = 'warehouse',
}

export type MapCornerProps = {
  cornerId: string;
  cornerType: MapCornerType;
  floor: number;
};

export enum ExitDirection {
  UP = 'up',
  DOWN = 'down',
  BI = 'bi',
}

export type MapGeoJson = FeatureCollection<MapCornerProps, MultiPolygon>;

export type MapMeshGeoJson = FeatureCollection<null, LineString>;

export type MapExitsGeoJson = FeatureCollection<
  {
    groupId: string;
    type: TransferType;
    direction: ExitDirection;
  },
  Point
>;

export type MapMeta = {
  cornerType: MapCornerType;
  floor: number;
  cornerIds: string[];
  name?: LocalizedMap;
  link?: string;
  mapLink: string;
  logo: Image;
};

export enum TransferType {
  WALK = 'walk',
  ESCALATOR = 'escalator',
  STAIRS = 'stairs',
  LIFT = 'lift',
}

export interface Transfer {
  type: TransferType;
}

export interface WithinFloorTransfer {
  type: TransferType.WALK;
  distance: number;
  floor: number;
}

export interface BetweenFloorsTransfer {
  type: TransferType.ESCALATOR | TransferType.STAIRS | TransferType.LIFT;
  startFloor: number;
  endFloor: number;
}

export type MapRouteMeta = (WithinFloorTransfer | BetweenFloorsTransfer)[];

export type SearchResult = {
  id: string;
  name: LocalizedMap;
  link: string;
};
